body {
  background-color: #f8f9fa !important;
  letter-spacing: 1px !important;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.view-all-product-btn {
  background-color: #fff;
}

.view-all-product-btn:hover {
  background-color: #1976d31a !important;
  transition: 1s;
}

.text-primary {
  /* color: #1976d2 !important; */
  color: #22696b !important;
}

.text-red {
  color: red;
}

.bg-primary {
  background-color: #1976d2 !important;
}

.bg-button-primary {
  background-color: #22696b !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed, .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #22696b !important;
}

.border-primary {
  /* border: 1px solid #1976d2 !important; */
  border: 1px solid #22696b !important;
}

.mt-top-contennt {
  margin-top: 100px !important;
}

.custom-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.custom-box-shadow:hover {
  box-shadow: rgb(25 118 210) 0px 5px 15px !important;
  transition: 1s;
}

.css-188mx6n-MuiSlider-root {
  min-width: 190px !important;
}

.full-page-loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  background-color: #000000e3;
  backdrop-filter: blur(5px);
  z-index: 9999;
}

.coupon-header {
  background: rgb(255,236,192);
  background: linear-gradient(90deg, rgba(255,236,192,1) 0%, rgba(162,132,59,1) 60%, rgba(247,207,111,1) 100%);
}
.coupon {
  background: rgb(0, 37, 30);
  background: linear-gradient(90deg, rgba(0, 37, 30, 1) 0%, rgba(0, 59, 53, 1) 60%, rgba(12, 170, 154, 1) 100%);
}

/* .full-page-loader-icon {
  font-size: 3rem;
  color: #000;
} */

.loader {
  font-size: 48px;
  display: inline-block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  /* color: #263238; */
  color: #fff;
  box-sizing: border-box;
  /* text-shadow: 0 0 2px #FFF, 0 0 1px #FFF, 0 0 1px #FFF; */
  letter-spacing: 2px;
  position: relative;
}
.loader::after {
  content: 'StudioSasvat';
  position: absolute;
  left: 0;
  top: 0;
  /* color: #FFF; */
  color: #22696b;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  animation: animloader 10s linear infinite;
}

@keyframes animloader {
  0% {
    width: 0%;
  }
  100% {
    /* width: 100%; */
    width: 150%;
  }
}

.bg-footer {
  /* background-color: #bbddff; */
  background-color: #48a5ac21;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  align-items: baseline !important;
}

th, td {
  font-size: 14px !important;
}

.iiz__img {
  max-height: 340px;
}

.nav-search-icon-mobile {
  display: none;
}

.nav-main-logo {
  font-size: 37px;
}

.nav-action-buttons-mobile {
  display: none !important;
}

.nav-menus {
  display: block;
}

.nav-mobile-menus:hover{
  background-color: #fff !important;
  color: #000 !important;
}

.box-shadow-custom {
  box-shadow: rgba(0, 0, 0, 0.35) 13px 15px 15px !important;
}

.product-detail-tabs{
  font-size: 15px;
}

.my-profile-button-section{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.my-profile-buttons{
  width: 48%;
}

.site-rating-button {
  position: fixed; 
  bottom: 0; 
  width: 100%; 
  z-index: 9999999;
}

.product-detail-image {
  height: 500px;
  width: 500px;
}

.theme-image-lg{
  width: 600px;
  height: 600px;
}

/* .theme-image-lg:hover{
  box-shadow: rgb(255 255 255) -2px 20px 30px -10px;
  transition: 1s; 
  cursor: pointer;
} */

.style-line-section {
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 7%, rgba(33,37,41,1) 100%);
}

.product-card-product-name{
  font-size: 22px;
}

.product-card-product-price {
  font-size: 18px;
}

.new-arrival-heading {
  font-size: 50px;
  line-height: 60px;
}

.logo-with-heading {
  height: 35px;
}

.new-arrival-heading-section {
  margin: 5px 0 85px 0;
}

.new-arrival-sm-heading-section {
  margin-top: 45px;
}

.category-with-product-section {
  margin-top: 100px;
}

.content-padding{
  padding: 0 80px;
}

/* width */
.product-section::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.product-section::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px lightgrey; 
  border-radius: 10px;
}
 
/* Handle */
.product-section::-webkit-scrollbar-thumb {
  background: #22696b; 
  border-radius: 10px;
}

/* Handle on hover */
.product-section::-webkit-scrollbar-thumb:hover {
  background: #22696b; 
}

@media only screen and (max-width: 678px) {
  .content-padding{
    padding: 0 2px !important;
  }
}

@media only screen and (max-width: 768px) {
  .new-arrival-heading {
    font-size: 18px;
    line-height: 20px;
  }
  .logo-with-heading {
    height: 20px !important;
  }
  .new-arrival-heading-section {
    margin: 5px 0 25px 0;
  }
  .new-arrival-sm-heading-section {
    margin-top: 10px !important;
  }
  .category-with-product-section{
    margin-top: 35px !important;
  }
}

@media only screen and (max-width: 576px) {
  .product-card-product-name{
    font-size: 14px !important;
  }
  
  .product-card-product-price {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 991px) {
  .theme-image-lg{
    width: 450px;
    height: 450px;
  }
}
@media only screen and (max-width: 585px) {
  .theme-image-lg{
    width: 170px;
    height: 170px;
  }
}
@media only screen and (max-width: 991px) {
  .site-rating-button {
    bottom: 56px !important;
  }
}
@media only screen and (max-width: 768px) {
  .product-detail-tabs{
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 510px) {
  .product-detail-tabs{
    font-size: 13px !important;
  }
}
@media only screen and (max-width: 448px) {
  .product-detail-tabs{
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 360px) {
  .product-detail-tabs{
    font-size: 8px !important;
  }
}

@media only screen and (max-width: 910px) {
  .nav-search-icon{
    display: none !important;
  }
  .nav-action-buttons{
    display: none !important;
  }
  .nav-search-icon-mobile {
    display: block !important;
  }
  .nav-action-buttons-mobile {
    display: block !important;
  }
  .nav-main-logo {
    font-size: 20px !important;
  }
  .nav-menus {
    display: none !important;
  }
  .marquee-content {
    animation: marquee 15s linear infinite !important;
  }
}

@media only screen and (min-width: 992px) {
  .bottom-nav {
    display: none !important;
  }
  .nav-search-bar {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .footer {
    /* display: none !important; */
    margin-bottom: 50px;
  }
  .site-content {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .mt-top-contennt {
    margin-top: 120px !important;
  }
}

.product-image-section {
  height: 250px;
}

.heart-cart-button-section {
  display: flex;
  align-items: center !important;
  flex-wrap: nowrap;
}

.cart-button-sm{
  display: none !important;
}

@media only screen and (max-width: 468px) {
  .product-detail-image {
    height: 350px !important;
  }
}
@media only screen and (max-width: 576px) {
  .product-card {
    width: 50% !important;
  }
  .product-image-section {
    height: 150px !important;
  }
  .heart-cart-button-section {
    align-items: center !important;
    /* justify-content: center; */
    flex-wrap: wrap !important;
  }
  .cart-button-lg{
    display: none !important;
  }
  .cart-button-sm{
    display: block !important;
  }
  .free-delivery-section {
    display: none !important;
  }
  .discount-section {
    font-size: 8px !important;
  }
}

.marquee {
  width: 100%; /* or set a specific width */
  overflow: hidden;
  position: relative;
  background-color: #fff; /* Optional: set a background color */
}

.marquee-content {
  display: flex;
  align-items: center;
  gap: 20px; /* Adjust as needed */
  width: 100%; /* Ensure the content spans the full width */
  white-space: nowrap;
  animation: marquee 50s linear infinite;
}

@keyframes marquee {
  0% {
      transform: translateX(100%);
  }
  100% {
      transform: translateX(-100%);
  }
}